import React, { useState, useRef } from 'react';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [serverErrors, setServerErrors] = useState('');


  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError('');
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file before uploading.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
  
      const response = await fetch('https://backendsc.marsbpo.org/api/employees/upload-employees', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        setShowModal(true);
        setFile(null);
        setLoading(false);
        setError(null);
      } else {
        if (responseData && responseData.errors) {
          const errorMessages = responseData.errors.map((error) => error.message);
          setServerErrors(`Error uploading file: ${errorMessages.join(', ')}`);
        } else {
          setError(responseData.message || 'Error uploading file.');
        }
        setLoading(false);
      }
    } catch (error) {
      setError('Error uploading file. Please try again.');
      setLoading(false);
      console.error('Error uploading file:', error);
    }
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <div         style={{ backgroundColor: "#F4F4F4" }}
    className="flex flex-col items-center">
    {serverErrors && <p className="text-red-500 mt-2">{serverErrors}</p>}

      <div
        className="w-full h-40 border-dashed border-2 border-black rounded-md cursor-pointer"
        onClick={openFileDialog}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".xlsx"
          className="hidden"
        />
        <p className="text-center pt-12">
          {file ? `File selected: ${file.name}` : 'Click to select Excel file'}
        </p>
      </div>
      <button
        className={`mt-4 bg-blue-950 py-2 px-4 rounded text-white ${loading || !file ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleUpload}
        disabled={loading || !file}
      >
        {loading ? 'Uploading...' : 'Upload'}
      </button>
      <button
        className="mt-4 p-2 border border-gray-300 rounded cursor-pointer"
        onClick={openFileDialog}
      >
        Select Excel File
      </button>

      {error && <p className="text-red-500 mt-2">{error}</p>}

      {showModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  {/* Checkmark icon or success icon can be added here */}
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    Upload Successful
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Your file has been uploaded successfully!
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="inline-flex justify-center w-full sm:w-auto border border-transparent rounded-md shadow-sm px-4 py-2 bg-red-900 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:order-0 sm:ml-3  sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
