import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Home from './Home';
import AddEmployee from '../AddEmployees/AddEmployee';
import Departments from '../Departments';
import Attendance from '../Attendance';
// import Payroll from '../payrolls/Payroll';

const Main = () => {
  const [selectedOption, setSelectedOption] = useState('Home');

  const renderComponent = () => {
    switch (selectedOption) {
      case 'Home':
        return <Home />;
      case 'AddEmployee':
        return <AddEmployee />;
      case 'Departments':
        return <Departments />;
      case 'Attendance':
        return <Attendance />;
        // case 'payroll':
        //   return <Payroll />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="flex bg-white min-h-screen">
      <Sidebar onSelectOption={(option) => setSelectedOption(option)} />
      <div className="flex-grow lg:pl-52 text-gray-800">
        <Header />
        {renderComponent()}
      </div>
    </div>
  );
};

export default Main;
