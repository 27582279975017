import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";

const EmployeeProfilePage = () => {
  const location = useLocation();
  const { employee } = location.state;
  const [updatedEmployee, setUpdatedEmployee] = useState({ ...employee });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://backendsc.marsbpo.org/api/employees/update/${employee.id}`,
        updatedEmployee,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error updating employee:", error);
      // Handle error updating employee
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `https://backendsc.marsbpo.org/api/employees/delete/${employee.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowSuccessModal(true);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error updating employee:", error);
      // Handle error updating employee
    }
  };

  return (
    <>
      {showSuccessModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-lg">
            <p className="text-2xl font-semibold text-gray-800">Success!</p>
            <p className="text-gray-700 mt-4">
              Operation completed successfully.
            </p>
            <button
              onClick={() => setShowSuccessModal(false)}
              className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className="relative">
        <button
          onClick={() => window.history.back()}
          className="absolute left-20 top-5 flex items-center rounded-full bg-blue-950 text-white py-2 px-2 mt-4 ml-4"
        >
          <FaArrowLeft className="mr-2" />
          Go Back
        </button>
      </div>

      <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center px-4 py-20">
        <div className="max-w-4xl w-full rounded-lg overflow-hidden shadow-lg lg:ml-40 bg-white">
          <form onSubmit={handleUpdate} className="p-8">
            <h2 className="text-3xl font-bold text-gray-800 mb-8">
              Employee Profile
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-lg font-semibold text-blue-950">
                  Name
                </label>
                <input
                  type="text"
                  name="employeeName"
                  value={updatedEmployee.employeeName}
                  onChange={handleInputChange}
                  className="border border-gray-300 focus:outline-none w-full py-2 px-4 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-lg font-semibold text-blue-950">
                  Father Name
                </label>
                <input
                  type="text"
                  name="fatherName"
                  value={updatedEmployee.fatherName}
                  onChange={handleInputChange}
                  className="border border-gray-300 focus:outline-none w-full py-2 px-4 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-lg font-semibold text-blue-950">
                  Role
                </label>
                <input
                  type="text"
                  name="role"
                  value={updatedEmployee.role}
                  onChange={handleInputChange}
                  className="border border-gray-300 focus:outline-none w-full py-2 px-4 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-lg font-semibold text-blue-950">
                  Department
                </label>
                <input
                  type="text"
                  name="department"
                  value={updatedEmployee.department}
                  onChange={handleInputChange}
                  className="border border-gray-300 focus:outline-none w-full py-2 px-4 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-lg font-semibold text-blue-950">
                  Date of Joining
                </label>
                <input
                  type="text"
                  name="dateOfJoining"
                  value={updatedEmployee.dateOfJoining}
                  onChange={handleInputChange}
                  className="border border-gray-300 focus:outline-none w-full py-2 px-4 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-lg font-semibold text-blue-950">
                  CNIC
                </label>
                <input
                  type="text"
                  name="cnic"
                  value={updatedEmployee.cnic}
                  onChange={handleInputChange}
                  className="border border-gray-300 focus:outline-none w-full py-2 px-4 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-lg font-semibold text-blue-950">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={updatedEmployee.email}
                  onChange={handleInputChange}
                  className="border border-gray-300 focus:outline-none w-full py-2 px-4 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-lg font-semibold text-blue-950">
                  Emergency Contact No.
                </label>
                <input
                  type="text"
                  name="emergencyContact"
                  value={updatedEmployee.emergencyContact}
                  onChange={handleInputChange}
                  className="border border-gray-300 focus:outline-none w-full py-2 px-4 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-lg font-semibold text-blue-950">
                  Salary
                </label>
                <input
                  type="text"
                  name="salary"
                  value={updatedEmployee.salary}
                  onChange={handleInputChange}
                  className="border border-gray-300 focus:outline-none w-full py-2 px-4 rounded-lg"
                />
              </div>
            </div>
            <div className="flex justify-between mt-8">
              <button
                type="submit"
                className="bg-blue-500 text-white px-3 py-3 rounded-full hover:bg-blue-600 focus:outline-none"
              >
                Update Profile
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-500 text-white px-3 py-3 rounded-full hover:bg-red-600 focus:outline-none"
              >
                Delete Employee
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EmployeeProfilePage;
