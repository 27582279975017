import React, { useState } from "react";
import {
  AiFillHome,
  AiOutlineUserAdd,
  AiOutlineApartment,
  AiOutlineSchedule,
} from "react-icons/ai";

const Sidebar = (props) => {
  const [activeTab, setActiveTab] = useState(null);

  const handleSelectOption = (option) => {
    setActiveTab(option);
    props.onSelectOption(option);
  };

  return (
    <aside
      id="sidebar"
      className={`sm:flex sm:flex-col ${
        props.showSidebar ? "" : "hidden"
      } fixed left-0 top-0 h-screen w-52 overflow-y-auto`}
    >
      <div className="bg-blue-950 flex items-center justify-center h-20">
        <img
          src="https://marsbpo.org/images/marsbpo.png"
          alt="company-logo"
          className="object-contain h-32 w-24" 
        />
      </div>

      <div
        style={{ backgroundColor: "#F4F4F4" }}
        className="flex-grow flex flex-col justify-between text-black"
      >
        <nav className="flex flex-col mx-4 my-6 space-y-6">
          <div
            onClick={() => handleSelectOption("Home")}
            className={`relative flex items-center py-3 pl-4 pr-6 text-black rounded-lg cursor-pointer ${
              activeTab === "Home"
                ? "bg-white"
                : "hover:bg-white hover:text-black"
            }`}
          >
            <AiFillHome
              className={`w-6 h-6 ${
                activeTab === "Home"
                  ? "text-black"
                  : "text-black hover:text-black"
              }`}
            />
            <span className="ml-2">Home</span>
            {activeTab === "Home" && (
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-950"></span>
            )}
          </div>
          <div
            onClick={() => handleSelectOption("AddEmployee")}
            className={`relative flex items-center py-3 pl-4 pr-6 text-black rounded-lg cursor-pointer ${
              activeTab === "AddEmployee"
                ? "bg-white"
                : "hover:bg-white hover:text-black"
            }`}
          >
            <AiOutlineUserAdd
              className={`w-6 h-6 ${
                activeTab === "AddEmployee"
                  ? "text-black"
                  : "text-black hover:text-black"
              }`}
            />
            <span className="ml-2">Add Employee</span>
            {activeTab === "AddEmployee" && (
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-950"></span>
            )}
          </div>
          <div
            onClick={() => handleSelectOption("Departments")}
            className={`relative flex items-center py-3 pl-4 pr-6 text-black rounded-lg cursor-pointer ${
              activeTab === "Departments"
                ? "bg-white"
                : "hover:bg-white hover:text-black"
            }`}
          >
            <AiOutlineApartment
              className={`w-6 h-6 ${
                activeTab === "Departments"
                  ? "text-black"
                  : "text-black hover:text-black"
              }`}
            />
            <span className="ml-2">Departments</span>
            {activeTab === "Departments" && (
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-950"></span>
            )}
          </div>
          <div
            onClick={() => handleSelectOption("Attendance")}
            className={`relative flex items-center py-3 pl-4 pr-6 text-black rounded-lg cursor-pointer ${
              activeTab === "Attendance"
                ? "bg-white"
                : "hover:bg-white hover:text-black"
            }`}
          >
            <AiOutlineSchedule
              className={`w-6 h-6 ${
                activeTab === "Attendance"
                  ? "text-black"
                  : "text-black hover:text-black"
              }`}
            />
            <span className="ml-2">Attendance</span>
            {activeTab === "Attendance" && (
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-950"></span>
            )}
          </div>
          {/* <div
            onClick={() => handleSelectOption("payroll")}
            className={`relative flex items-center py-3 pl-4 pr-6 text-black rounded-lg cursor-pointer ${
              activeTab === "payroll"
                ? "bg-white"
                : "hover:bg-white hover:text-black"
            }`}
          >
            <AiOutlineSchedule
              className={`w-6 h-6 ${
                activeTab === "payroll"
                  ? "text-black"
                  : "text-black hover:text-black"
              }`}
            />
            <span className="ml-2">Payrolls</span>
            {activeTab === "payroll" && (
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-950"></span>
            )}
          </div> */}
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
