import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Test from "./components/Dashboard/Main";
import "./App.css";
import Signup from "./components/Signup";
import { jwtDecode } from "jwt-decode";
import AbsentEmployees from "./components/AttendanceDetails/AbsentEmployees";
import PresentEmployees from "./components/AttendanceDetails/PresentEmployees";
import LeaveEmployees from "./components/AttendanceDetails/LeaveEployees";
import LateEmployees from "./components/AttendanceDetails/LateEmployees";
import AllEmployees from "./components/AttendanceDetails/AllEmployees";
import EmployeeDashboard from "./components/Employee/Home";
import NotFound from "./components/error/NotFound";
import EmployeeProfile from "./components/EmployeeProfile";
const App = () => {
  const [tokenStored, setTokenStored] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      // Simulating fetching token from local storage
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const token = localStorage.getItem("token");
      console.log(token)
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          setTokenExpired(true);
          setTimeout(() => {
            localStorage.removeItem("token");
            setTokenStored(false);
            setTokenExpired(false); // Set tokenExpired to false when token is removed
          }, 5000);
        } else {
          setTokenStored(true);
          setUserRole(decodedToken.userRole);
        }
      }
      setLoading(false); // Set loading to false after token check
    };

    fetchToken();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/"
          element={
            tokenStored ? (
              userRole === "hr" ? (
                <Test />
              ) : (
                <EmployeeDashboard />
              )
            ) : (
              <Login setTokenStored={setTokenStored} />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            tokenStored && userRole === "hr" ? (
              <Test />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/employee"
          element={
            tokenStored && userRole === "employee" ? (
              <EmployeeDashboard />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        {/* Other routes */}
        <Route path="/absent-employees-details" element={<AbsentEmployees />} />
        <Route
          path="/present-employees-details"
          element={<PresentEmployees />}
        />
        <Route path="/leave-employees-details" element={<LeaveEmployees />} />
        <Route path="/late-employees-details" element={<LateEmployees />} />
        <Route path="/all-employees-details" element={<AllEmployees />} />
        <Route path="/employee/profile/:id" element={<EmployeeProfile />} />


        {/* 404 page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {tokenExpired && (
        <div className="fixed top-0 left-0 w-full bg-red-500 text-white p-4 text-2xl  text-center">
          Your session has expired. Please log in again.
        </div>
      )}
    </BrowserRouter>
  );
};

export default App;
